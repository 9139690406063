<template>
  <div class="container">
    <a-page-header title="未分配角色的账号" />

    <div class="main-content">
      <div
        class="content"
        v-for="item in tabs"
        :key="item.key"
        :class="'content-' + item.key"
      >
        <div class="main-content-header">
          <div class="item">
            <span class="title">{{ item.name }}</span>
          </div>
        </div>
        <my-table
          :columns="columns"
          :data-source="item.data"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.systemNum"
          :scroll="{ x: 780 }"
        >
          <template slot="action" slot-scope="record">
            <a-button type="link" @click="handleModalOpen(record)">
              分配角色
            </a-button>
          </template>
        </my-table>
      </div>
    </div>

    <a-modal
      title="分配角色"
      :visible="modalVisible"
      :confirm-loading="confirmModalLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
    >
      <a-form-model ref="ruleModalForm" :model="modalForm" :rules="modalRules">
        <a-form-model-item ref="modalId" label="选择角色" prop="id">
          <a-cascader
            v-model="modalForm.id"
            :options="cascaderData"
            expand-trigger="hover"
            :field-names="fieldNames"
            placeholder="请选择角色"
            @blur="
              () => {
                $refs.modalId.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import myTable from '@/components/my-table'
let account = ''
export default {
  data() {
    return {
      loading: true,
      tabs: [
        {
          key: 0,
          name: '中台管理账号',
          data: []
        },
        {
          key: 1,
          name: '中台业务账号',
          data: []
        }
      ],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '登录账号',
          dataIndex: 'systemNum',
          width: 220
        },
        {
          title: '持有者姓名',
          dataIndex: 'realName',
          width: 150,
          customRender: (text) => {
            return text ? text : '-'
          }
        },
        {
          title: '账户类型',
          dataIndex: 'userType',
          customRender: (text) => {
            return this.userTypes[text]
          }
        },
        {
          title: '操作',
          fixed: 'right',
          width: 150,
          scopedSlots: { customRender: 'action' }
        }
      ],
      modalVisible: false,
      confirmModalLoading: false,
      modalForm: {
        id: []
      },
      modalRules: {
        id: [{ required: true, message: '请选择角色', trigger: 'blur' }]
      },
      cascaderData: [],
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'levelList'
      },
      userTypes: {
        Proxy: '企业',
        training: '实操讲师',
        lector: '理论讲师',
        business: '渠道商',
        bterminal: '渠道商终端',
        salesman: '业务员',
        store:"门店账号",
        community:"社区",
        fowller:"监工"
      }
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  components: {
    myTable
  },
  created() {
    this.getAccounUnrole()
  },
  methods: {
    getAccounUnrole() {
      // 获取未分配角色的账号
      this.$axios.getAccounUnrole().then((res) => {
        const d = res.data.data
        console.log("d",d)
        this.tabs[0].data = d.Madmin
        this.tabs[1].data = d.Salesman
        this.loading = false
      })
    },
    handleModalOpen(e) {
      // 打开分配角色
      account = e
      this.modalVisible = true
      console.log("e",e)
      if (!this.cascaderData.length) {
        this.getRoleBasedAccessControl()
      }
    },
    getRoleBasedAccessControl() {
      // 获取基于角色的权限管理
      this.$axios.getRoleBasedAccessControl().then((res) => {
        this.cascaderData = res.data.data
      })
    },
    handleModalOk() {
      // 确定分配角色
      this.$refs.ruleModalForm.validate((valid) => {
        if (valid) {
          this.confirmModalLoading = true
          const data = {
            id: account.id,
            role_level: this.modalForm.id[1],
            role_id: this.modalForm.id[0],
            type: account.userType
          }

          this.$axios.distributeAccountRole(data).then(() => {
            this.confirmModalLoading = false
            this.$message.success('操作成功')
            this.handleModalCancel()
            this.getAccounUnrole()
          })
        } else {
          return false
        }
      })
    },
    handleModalCancel() {
      // 取消分配角色
      account = ''
      this.$refs.ruleModalForm.resetFields()
      this.modalVisible = false
      this.confirmModalLoading = false
    }
  }
}
</script>
<style scoped>
.content {
  background-color: #fff;
}

.content-1 {
  position: relative;
  padding-top: 32px;
}

.content-1::before {
  content: '';
  position: absolute;
  top: 16px;
  left: 32px;
  right: 32px;
  height: 1px;
  background-color: #e8e8e8;
}
</style>
